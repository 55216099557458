export default function navigationHandler() {
    /**
     * Blendet das mobile Menü ein und aus
     */
    const {body} = document;
    const toggles = document.querySelectorAll('.js-header-navigation-toggle');
     const navigationWrapper = document.querySelector('.js-header-navigation-wrapper');


    toggles.forEach((toggle) => {
        toggle.addEventListener('click', () => {
            body.classList.toggle('has-active-navigation');
            navigationWrapper.classList.toggle('is-open');
        });
    });

    /**
     * Dummy-Hauptmenüpunkte dürfen beim Klick nicht den Fokus behalten,
     * damit das Untermenü wieder ausgeblendet wird
     */
    const emptyElements = document.querySelectorAll('.js-empty-menu-element');

    emptyElements.forEach((element) => {
        element.addEventListener('click', (event) => {
            event.target.blur();
        });
    });
}
