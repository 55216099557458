import {getParentByClassName} from '../services/traversing';

export default function subMenuHandler() {
    const menu = document.querySelector('.js-menu');

    if (!menu) return;

    const navigation = document.querySelector('.js-navigation');

    const menuItems = menu.querySelectorAll('.js-menu-item');

    const handleBackClick = (event) => {
        event.preventDefault();
        event.stopImmediatePropagation();

        const {target} = event;
        const parent = getParentByClassName(target, 'is-active');
        navigation.style.removeProperty('min-height');
        if (parent) {
            parent.classList.remove('is-active');
        }
    };

    const handleClick = (event) => {
        const {target} = event;
        const subMenu = target.querySelector('.js-submenu');
        navigation.style.removeProperty('min-height');

        if (!subMenu) return;

        subMenu.classList.add('is-active');
        navigation.style.minHeight = `${subMenu.scrollHeight}px`;
    };

    menuItems.forEach((menuItem) => {
        const menuBackButtons = menuItem.querySelectorAll('.js-menu-back');

        menuBackButtons.forEach((menuBackButton) => {
            menuBackButton.addEventListener('click', (event) => {
                handleBackClick(event);
            });
        });

        menuItem.addEventListener('click', event => handleClick(event));
    });
}
